import { useMemo, useState,  useEffect } from "react";
import { Step, Stepper } from "react-form-stepper";
import { MdCheck, MdDescription,  } from "react-icons/md";
import StepWizard from "react-step-wizard";
import { FormGroup, Label, Row, Form, Col, Input } from "reactstrap";
import ReactSelect from "react-select";
import {
  Alert,
  Button,
  Modal,
  notification,
  Table,
  Typography,
} from "antd";
import moment from "moment";
// import {getUserIdentity} from "helpers/auth";
// import {extractEmailsFromString, generateDefaultProcurementName, get_html, replace_vars,} from "helpers";
// import {sendEmailAPI} from "apis";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./RiverMeadowWizard.css";
import appAxios from "../components/app_config/axios";
import useGlobalStore from "store";
import { shallow } from "zustand/shallow";
import { sleep } from "services/migserv_utils";
const { Text } = Typography;

const migservAPIURL = process.env.REACT_APP_MIGSERV_API_URL;

const ActionButtons = (props) => {
  const actionButtonStyle = {
    margin: "0 auto",
    backgroundColor: "teal",
    color: "white",
  };

  const handleBack = () => {
    props.previousStep();
  };

  const handleNext = () => {
    props.nextStep();
  };

  const handleFinish = () => {
    props.lastStep();
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Row>
        <Col>
          {props.currentStep > 1 && (
            <Button onClick={handleBack} style={actionButtonStyle}>
              Back
            </Button>
          )}
        </Col>
        <Col>
          {props.currentStep < props.totalSteps && (
            <Button onClick={handleNext} style={actionButtonStyle}>
              Next
            </Button>
          )}
          {props.currentStep === props.totalSteps && (
            <Button onClick={handleFinish} style={actionButtonStyle}>
              Submit
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

const InfoHeader = () => {
  return (
    <div style={{ marginTop: "20px" }}>
      <Row>
        <Col xs lg="1"></Col>
        <Col>
          <Label style={{ fontSize: "16px", fontWeight: "bold" }}>
            RiverMeadow Interest Form
          </Label>
        </Col>
        <Col xs lg="1"></Col>
      </Row>

      <Row>
        <Col xs lg="1"></Col>
        <Col>
          <Label style={{ fontSize: "14px" }}>
            This RiverMeadow Intake Wizard is the first step of the Intake
            process to request an application’s O/S and/or MSSQL Server database
            upgrade supported by Migration Services. This Wizard enables a quick
            and easy way for an application team to select their target
            application and identify a list of servers that need to be upgraded.
            The Wizard provides initial filtering to prevent selection of Server
            O/S’s and DB’s that are not currently is scope for the RiverMeadow
            service. Once the client completes the Intake Wizard, the Migration
            Services team will review the request and contact the requestor to
            approve a request to install a non-disruptive precheck agent on the
            servers to confirm eligibility for the server upgrade. For
            additional details see the link below:{" "}
            <a href="https://share.merck.com/spaces/DCMMIG/pages/1696401155/Operating+System+SQL+Database+Modernization+Using+RiverMeadow?src=contextnavpagetreemode">
              {" "}
              Click here
            </a>
          </Label>
        </Col>
        <Col xs lg="1"></Col>
      </Row>
    </div>
  );
};

/* FIRST PANEL - application */
const Application = (props) => {
  const [error, setError] = useState("");
  const [appShortNameSelectValue, setAppShortNameSelectValue] = useState(null);
  const [appCIIDSelectValue, setAppCIIDSelectValue] = useState(null);
  const [appShortNameOptions, setappShortNameOptions] = useState([]);
  const [appCIIDOptions, setappCIIDOptions] = useState([]);

  const validate = () => {
    if (!appShortNameSelectValue && !appCIIDSelectValue)
      setError("Please select an application");
    else {
      setError("");
      props.nextStep();
    }
  };

  /* Use the MIGSERV API to retrieve the Merck application short names and ci ids */
  const fetchAppnames = async () => {
    const result = await appAxios.get(migservAPIURL + "/applications");
    return result.data;
  };

  /* Use the MIGSERV API to retrieve the Merck application information for the given app ci id */
  const fetchAppinfo = async () => {
    const result = await appAxios.get(
      migservAPIURL + "/appinfo?",
      props.wizardInfo.app
    );
    return result.data;
  };

  /* Build option lists from the Merck application data */
  useMemo(() => {
    fetchAppnames()
      .then((items) => {
        var appShortNames = [];
        var appCIIDs = [];

        // Find all appnames that contain "ctx" and get their respective app_ci_ids
        const disabledAppCIIDs = items
          ?.filter((item) => item.appname.toLowerCase().includes("ctx"))
          ?.map((item) => item.app_ci_id); // Get all app_ci_ids that need to be disabled

        items.forEach((item) => {
          const isDisabled = disabledAppCIIDs.includes(item.app_ci_id);

          appShortNames = [
            ...appShortNames,
            {
              value: item.app_ci_id,
              label: item.appname,
              isDisabled: isDisabled,
            },
          ];

          appCIIDs = [
            ...appCIIDs,
            {
              value: item.app_ci_id,
              label: item.app_ci_id,
              isDisabled: isDisabled,
            },
          ];
        });

        setappShortNameOptions(appShortNames);
        setappCIIDOptions(appCIIDs);
      })
      .catch((error) => {
        notification.error({
          message: "Error fetching Application Data :" + error,
        });
      });
  }, []);

  const handleAppShortName = (value) => {
    setAppShortNameSelectValue(value);
    props.userCallback({
      app: value.value, // This is the app CI ID value
      appShortName: value.label,
      fetchUpdateData: true,
    });
    let appCIID = appCIIDOptions?.filter((item) => item.value === value.value);
    setAppCIIDSelectValue(appCIID);
  };

  const handleAppCIIDSelection = (value) => {
    setAppCIIDSelectValue(value);

    let appShortName = appShortNameOptions?.filter(
      (item) => item.value === value.value
    );
    setAppShortNameSelectValue(appShortName);
    console.log("appShortName", appShortName);
    props.userCallback({
      app: value.value, // This is the app CI ID value
      appShortName: appShortName[0]?.label,
      fetchUpdateData: true,
    });
  };

  return (
    <div>
      <h2
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Select Application
      </h2>
      <br />
      <Form
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "50px",
        }}
      >
        <FormGroup>
          <Row style={{ gap: "50px" }}>
            <Col>
              <Label style={{ fontSize: "16px" }}>Select by short name</Label>
            </Col>
            <Col>
              <Label style={{ fontSize: "16px" }}>Select by app CI ID</Label>
            </Col>
          </Row>
          <Row style={{ gap: "50px" }}>
            <Col>
              <ReactSelect
                options={appShortNameOptions}
                onChange={handleAppShortName}
                styles={{ container: (base) => ({ ...base, width: "250px" }) }}
                value={appShortNameSelectValue}
              />
            </Col>
            <Col>
              <ReactSelect
                options={appCIIDOptions}
                onChange={handleAppCIIDSelection}
                value={appCIIDSelectValue}
                styles={{ container: (base) => ({ ...base, width: "250px" }) }}
              />
            </Col>
          </Row>
        </FormGroup>
      </Form>
      <br />
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "red",
        }}
      >
        {error}
      </span>
      <br />
      <ActionButtons {...props} nextStep={validate} />
    </div>
  );
};

/* Servers */
const Servers = (props) => {
  const [error, setError] = useState("");
  const [appInfo, setAppInfo] = useState();
  const [serverData, setServerData] = useState([]);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [filteredData, setFilteredData] = useState([]);
  const [eligibleData, setEligibleData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  /* retrieve the appinfo for the selected app_ci_id */
  const fetchAppInfo = async (appCIID) => {
    const result = await appAxios.get(
      migservAPIURL + "/appinfo?server_info=" + appCIID
    );
    return result.data;
  };

  /* Build option lists from the Merck application data */
  useEffect(() => {
    if (!props.wizardInfo.app) return; // ✅ Prevent API call if no app CI ID is selected

    fetchAppInfo(props.wizardInfo.app)
      .then((items) => {
        console.log("result", items);
        // console.log("result",JSON.stringify(items))
        filteringEligibleData(items);
        setServerData(items || []);
        const appInfo =
          items?.Migrations?.map((item) => ({
            Servername: item.Servername,
            Environment: item.Environment,
          })) || [];
        setAppInfo(appInfo);
      })
      .catch((error) => {
        notification.error({
          message: "Error fetching Server Information :" + error,
        });
      });

    console.debug("APPINFO", appInfo);
  }, [props.wizardInfo.app]);

  const validate = () => {
    setError("");
    props.nextStep();
  };

  const columns = [
    {
      title: "Server Name",
      dataIndex: "Servername",
      key: "Servername",
    },
    {
      title: "Environment",
      dataIndex: "Environment",
      key: "Environment",
    },
    {
      title: "Mapped Service",
      dataIndex: "MappedService",
      key: "MappedService",
    },
    {
      title: "OS",
      dataIndex: "OS",
      key: "OS",
    },
    {
      title: "Site",
      dataIndex: "Site",
      key: "Site",
    },
    {
      title: "DB",
      dataIndex: "DB",
      key: "DB",
    },
    {
      title: "DB Version",
      dataIndex: "DBBaseVersion",
      key: "DBBaseVersion",
    },
    {
      title: "DB Name",
      dataIndex: "DBName",
      key: "DBName",
    },
    {
      title: "Cluster",
      dataIndex: "Cluster",
      key: "Cluster",
    },
    {
      title: "Cluster Meter Rollup",
      dataIndex: "ClusterMetrRollup",
      key: "ClusterMetrRollup",
    },
    {
      title: "Server Shared",
      dataIndex: "ServerSharedAppAbbr",
      key: "ServerSharedAppAbbr",
      width: 200,
      render: (text) => (
        <div
          style={{
            wordWrap: "break-word",
            whiteSpace: "normal",
            overflow: "hidden",
            wordBreak: "break-all",
          }}
        >
          {text}
        </div>
      ),
    },
  ];

  const handleRowSelectionChange = (selectedRowKeys, selectedRows) => {
    props.userCallback({ selectedServers: selectedRows });
  };

  const rowSelection = {
    onChange: handleRowSelectionChange,
    getCheckboxProps: (record) => ({
      disabled: record?.disabled === true,
    }),
  };

  const filteringEligibleData = (serverData) => {
    const eligibilityCriteria = ["2008 r2", "2012", "2016"];
    const filteredDataForwindows = serverData.filter((server) =>
      server?.OS?.toLowerCase().includes("windows")
    ); //filtered data based on windows OS
    // console.log("filteredDataForwindows", filteredDataForwindows);

    const filteredDataBasedOnVersion = filteredDataForwindows.filter(
      (server) =>
        eligibilityCriteria.some((version) =>
          server?.OS?.toLowerCase().includes(version)
        ) //filtering windows data based on eligible version '2008 r2', '2012', '2016'
    );

    const filteredDataBasedOnDB = filteredDataBasedOnVersion.filter(
      (server) => {
        const db = server?.DB;
        // DB should either be null, empty, or contain 'mssql'
        return !db || db.trim() === "" || db?.toLowerCase().includes("mssql");
      }
    );

    //filtering if  name contains ts.
    const filteredDataexcludingTS = filteredDataBasedOnDB?.filter(
      (server) => !server?.Servername?.toLowerCase().includes("ts")
    );

    console.log("filteredDataBasedOnDB", filteredDataBasedOnDB);

    const filteredDataForNonWindows = serverData.filter(
      (server) => !filteredDataexcludingTS.includes(server)
    ); //filtering rest of the data

    const filteredDataToBeDisabled = filteredDataForNonWindows.map(
      (server) => ({
        ...server,
        disabled: true,
      })
    );

    console.log("filteredDataToBeDisabled", filteredDataToBeDisabled);

    const finalData = [...filteredDataexcludingTS, ...filteredDataToBeDisabled];
    setEligibleData(filteredDataexcludingTS);
    setFilteredData(finalData);
  };

  const handleShowAllServers = () => {
    setIsModalOpen(true);
  };

  return (
    <div>
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "red",
        }}
      >
        {error}
      </span>
      <Row>
        <Col xs lg="1"></Col>
        <Col span={12}>
          <h2>Eligible Rivermeadow server - {props.wizardInfo.appShortName}</h2>
        </Col>
        <Col span={12}>
          <Button type="primary" onClick={handleShowAllServers}>
            Application Server List
          </Button>
        </Col>
      </Row>
      <br />
      <div
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row>
          <Col xs lg="1"></Col>
          <Col>
            {eligibleData?.length === 0 ? (
              <Alert
                showIcon
                style={{ marginBottom: "30px", marginTop:'20px' }}
                description="No eligible servers found. Please click on Application Server List to show all servers."
                type="info"
              />
            ) : (
              <Table
                style={{ tableLayout: "fixed" }}
                columns={columns}
                dataSource={eligibleData}
                rowKey="Servername"
                bordered
                pagination={{ pageSize: 10 }}
                size="small"
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
              />
            )}
          </Col>
          <Col xs lg="1"></Col>
        </Row>
      </div>
      <ActionButtons {...props} nextStep={validate} />

      <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        width={1300}
        onClose={handleCancel}
      >
        <Row>
          <Col>
            <Table
              columns={columns}
              dataSource={filteredData}
              rowKey="Servername"
              bordered
              pagination={{ pageSize: 5 }}
              size="small"
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

/* Question 1 */
const Question1 = (props) => {
  const [error, setError] = useState("");
  const [q1Answer, setQ1Answer] = useState("");
  
  const question1 =
    "Are all application-to-application dependencies properly related in ServiceNow  ?";
  const question1desc =
    "In SNOW, application environments are mapped traditionally to a Dev, Test, Prod environment. Some application have other environments such as SIT, Training, UAT which are not in SNOW. It is important that these environments are mapped properly in SNOW or as an alternative, the application team provides this mapping to properly schedule each environment. It is the responsibility of the application owner to validate and correct any issues in SNOW prior to migration. Servers no longer being used need to be unrelated to the application by the application team.";

  const validate = () => {
    const Q1Answer = {
      q_name: question1,
      entity_type: "Question",
      q_description: question1desc,
      category: "SNOW",
      filter_tags: "",
      responsibility: "Application Team",
      SK: "Q|01",
      PK: "River Meadow Precheck",
      q_status: "Initial",
      responder_comments: q1Answer?.target?.value,
      admin_comments: "",
    };

    console.debug("Q1", q1Answer.target?.value);
    if (!q1Answer) setError("Please answer the question before continuing");
    else if (!q1Answer.target)
      setError("Please answer the question before continuing");
    else if (!q1Answer.target.value)
      setError("Please answer the question before continuing");
    else {
      setError("");
      props.nextStep();
      props.userCallback({
        data: [Q1Answer],
      });
    }
  };

  const previousStep = () => {
    props.previousStep();
    props.userCallback({
      data: [
        ...props.wizardInfo.data.filter((item) => item.SK !== "Q|01"), // Spread previous details
      ],
    });
  };

  return (
    <div>
      <Form
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center", 
        }}
      >
        <FormGroup>
          <Row>
            <Col xs lg="1"></Col>
            <Col>
              <Label>
                <h2>{question1}</h2>
              </Label>
            </Col>
            <Col xs lg="1"></Col>
          </Row>
          <br />
          <Row>
            <Col xs lg="1"></Col>
            <Col>
              <Label style={{ fontSize: "16px" }}>{question1desc}</Label>
            </Col>
            <Col xs lg="1"></Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col xs lg="1"></Col>
            <Col style={{ marginBottom: "10px" }}>
              <Label style={{ fontSize: "16px" }}>
                <b>Answer: </b>
              </Label>
            </Col>
            <Col xs lg="1"></Col>
          </Row>
          <Row>
            <Col xs lg="1"></Col>
            <Col>
              <Input
                type="textarea"
                rows={10}
                onChange={(value) => setQ1Answer(value)}
                style={{ fontSize: "16px" }}
              />
            </Col>
            <Col xs lg="1"></Col>
          </Row>
        </FormGroup>
      </Form>
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "red",
        }}
      >
        {error}
      </span>
      <ActionButtons
        {...props}
        nextStep={validate}
        previousStep={previousStep}
      />
    </div>
  );
};

/* Question 2 */
const Question2 = (props) => {
  const [error, setError] = useState("");
  const [q2Answer, setQ2Answer] = useState("");

  const question2 =
    "Are all application to application dependencies properly related to each application environment ?";
  const question2desc =
    "When a migration is scheduled and a change request is created, all applications that are dependent on that server require notification prior to the migration. Application to server relationships need to be reviewed and updated as necessary. It is the application owner’s / application managed by contact’s responsibility to communicate any downtime notifications to all applications that use your data and are not related in SNOW.";

  const validate = () => {
    const Q2Answer = {
      q_name: question2,
      entity_type: "Question",
      q_description: question2desc,
      category: "SNOW",
      filter_tags: "",
      responsibility: "Application Team",
      SK: "Q|02",
      PK: "River Meadow Precheck",
      q_status: "Initial",
      responder_comments: q2Answer.target?.value,
      admin_comments: "",
    };

    console.debug("Q2", q2Answer.target?.value);
    if (!q2Answer) setError("Please answer the question before continuing");
    else if (!q2Answer.target)
      setError("Please answer the question before continuing");
    else if (!q2Answer.target.value)
      setError("Please answer the question before continuing");
    else {
      setError("");
      props.nextStep();
      props.userCallback({
        data: [
          ...props.wizardInfo.data, // Spread previous details
          Q2Answer,
        ],
      });
    }
  };

  const previousStep = () => {
    props.previousStep();
    props.userCallback({
      data: [
        ...props.wizardInfo.data.filter((item) => item.SK !== "Q|02"), // Spread previous details
      ],
    });
  };

  return (
    <div>
      <Form
      >
        <FormGroup>
          <Row>
            <Col xs lg="1"></Col>
            <Col>
              <Label>
                <h2>{question2}</h2>
              </Label>
            </Col>
            <Col xs lg="1"></Col>
          </Row>
          <br />
          <Row>
            <Col xs lg="1"></Col>
            <Col style={{ fontSize: "16px" }}>
              <Label>{question2desc}</Label>
            </Col>
            <Col xs lg="1"></Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col xs lg="1"></Col>
            <Col style={{ marginBottom: "10px" }}>
              <Label style={{ fontSize: "16px" }}>
                <b>Answer:</b>
              </Label>
            </Col>
            <Col xs lg="1"></Col>
          </Row>
          <Row>
            <Col xs lg="1"></Col>
            <Col>
              <Input
                type="textarea"
                rows={10}
                onChange={(value) => setQ2Answer(value)}
                style={{ fontSize: "16px" }}
              />
            </Col>
            <Col xs lg="1"></Col>
          </Row>
        </FormGroup>
      </Form>
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "red",
        }}
      >
        {error}
      </span>
      <ActionButtons
        {...props}
        nextStep={validate}
        previousStep={previousStep}
      />
    </div>
  );
};

/* Last PANEL - Question 3 */
const Question3 = (props) => {
  const [q3Answer, setQ3Answer] = useState("");

  const question3 =
    " Are there any other Application considerations not covered that need to be addressed ? Please explain.";
  const question3desc =
    "  Any other considerations not covered in the questions posted please notate in this section.";

  const validate = () => {
    const Q3Answer = {
      q_name: question3,
      entity_type: "Question",
      q_description: question3desc,
      category: "SNOW",
      filter_tags: "",
      responsibility: "Application Team",
      SK: "Q|03",
      PK: "River Meadow Precheck",
      q_status: "Initial",
      responder_comments: q3Answer.target?.value,
      admin_comments: "",
    };
    props.lastStep();
    props.userCallback({
      data: [
        ...props.wizardInfo.data, // Spread previous details
        Q3Answer,
      ],
    });
  };

  return (
    <div>
      <Form
      >
        <FormGroup>
          <Row>
            <Col xs lg="1"></Col>
            <Col>
              <Label>
                <h2>
                  Are there any other Application considerations not covered
                  that need to be addressed ? Please explain.
                </h2>
              </Label>
            </Col>
            <Col xs lg="1"></Col>
          </Row>
          <br />
          <Row>
            <Col xs lg="1"></Col>
            <Col style={{ fontSize: "16px" }}>
              <Label>
                Any other considerations not covered in the questions posted
                please notate in this section.
              </Label>
            </Col>
            <Col xs lg="1"></Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col xs lg="1"></Col>
            <Col style={{ marginBottom: "10px" }}>
              <Label style={{ fontSize: "16px" }}>
                <b>Answer: </b>
              </Label>
            </Col>
            <Col xs lg="1"></Col>
          </Row>
          <Row>
            <Col xs lg="1"></Col>
            <Col>
              <Input
                type="textarea"
                rows={10}
                onChange={(value) => setQ3Answer(value)}
                style={{ fontSize: "16px" }}
              />
            </Col>
            <Col xs lg="1"></Col>
          </Row>
        </FormGroup>
      </Form>
      <ActionButtons {...props} lastStep={validate} />
    </div>
  );
};

const IntakeWizard = () => {
  const [stepWizard, setStepWizard] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [wizardInfo, setWizardInfo] = useState({});
  const navigate = useNavigate();
  const assignStepWizard = (instance) => {
    setStepWizard(instance);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const { v2_questionnaire_api, appinfo_api } = useGlobalStore(
    (state) => ({
      v2_questionnaire_api: state.v2_questionnaire_api,
      appinfo_api: state.appinfo_api,
    }),
    shallow
  );

  const assignWizardInfo = (val) => {
    setWizardInfo((wizardInfo) => ({
      ...wizardInfo,
      ...val,
    }));
  };

  const handleStepChange = (e) => {
    setActiveStep(e.activeStep - 1);
  };

  // Create a new questionnaire in the DB and send notification
  const handleComplete = async (x) => {
    setWizardInfo((wizardInfo) => ({
      ...wizardInfo,
      ...x,
    }));

    const updated_data = {
      ...wizardInfo,
      ...x,
    };
    console.log("updated_data", updated_data);
    create_questionnaire(updated_data);
  };

  //create questioonaire function
  const create_questionnaire = async (updated_Wizard_info) => {
    let app_ci_id = updated_Wizard_info["app"];
    let mig_type = "River Meadow Precheck";

    const question4 = {
      q_name: "Selected list of servers",
      entity_type: "Question",
      q_description: "Selected list of servers",
      category: "SNOW",
      filter_tags: "",
      responsibility: "Application Team",
      SK: "Q|04",
      PK: "River Meadow Precheck",
      q_status: "Initial",
      selected_servers: updated_Wizard_info?.selectedServers ?? [],
    };

    let response_appinfo = await appinfo_api(app_ci_id);
    if (
      !(
        response_appinfo.success &&
        response_appinfo.data &&
        response_appinfo.data["Appname"]
      )
    ) {
      notification.error({
        message: `App CI ID = ${app_ci_id} is not valid. Questionnaire can't be created`,
        key: "fetch",
      });
      return;
    }

    let app_name = response_appinfo.data["Appname"];
    let PK = app_ci_id + "|" + mig_type;
    let filterBy = { PK: PK, SK: PK };
    // Check if Questionnaire already exists, for given app_ci_id & mig_type
    let response = await v2_questionnaire_api("getQuestionnaireInfo", filterBy);

    if (response.success) {
      notification.error({
        message: `Questionnaire for the given App CI ID = ${app_ci_id} & Migration Type = ${mig_type} already exists.
        Loading the existing record`,
        key: "fetch",
      });
      return response.data;
    } else {
      notification.info({
        message: `Creating Questionnaire, please wait.. `,
        key: "fetch",
      });
      // Create new Questionnaire
      let questionnaire_item = {
        PK: PK,
        SK: PK,
        entity_type: "Questionnaire",
        app_ci_id: app_ci_id,
        mig_type: mig_type,
        app_name: app_name,
        q_status: "Initial",
      };

      let questionnaire_response = await v2_questionnaire_api(
        "create",
        undefined,
        undefined,
        questionnaire_item
      );
      console.log("questionnaire_response", questionnaire_response);

      // Insert all questions (template) for given questionnaire
      updated_Wizard_info?.data?.map((question) => {
        v2_questionnaire_api("create", undefined, undefined, {
          ...question,
          PK: PK,
        });
      });

      v2_questionnaire_api("create", undefined, undefined, {
        ...question4,
        PK: PK,
      });

      if (questionnaire_response.success) {
        notification.success({
          message:
            "Questionnaire initialized successfully, refreshing window ...",
          key: "fetch",
        });
        // Redirect to Questionnaire List screen
        await sleep(1500);
        showModal();
      } else {
        notification.error({
          message:
            "Some Error Occurred. Please refresh the page and try again ...",
          key: "fetch",
        });
      }
    }

    return null;
  };

  return (
    <div>
      <Row>
        <InfoHeader />
        <Stepper activeStep={activeStep}>
          <Step label="Choose Application" children={<MdCheck />} />
          <Step label="Server Selection" children={<MdCheck />} />
          <Step label="Question 1" children={<MdDescription />} />
          <Step label="Question 2" children={<MdDescription />} />
          <Step label="Question 3" children={<MdDescription />} />
        </Stepper>
      </Row>
      {/* NOTE: IMPORTANT !! StepWizard must contains at least 2 children components, else got error */}
      <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
        <Application userCallback={assignWizardInfo} />
        <Servers wizardInfo={wizardInfo} userCallback={assignWizardInfo} />
        <Question1 wizardInfo={wizardInfo} userCallback={assignWizardInfo} />
        <Question2 wizardInfo={wizardInfo} userCallback={assignWizardInfo} />
        <Question3 wizardInfo={wizardInfo} userCallback={handleComplete} />
      </StepWizard>
      {
        <Modal title="Request submitted" open={isModalOpen} onOk={handleOk}>
          <p>
            Thank you, your request has been submitted successfully. You may
            close the browser window.
          </p>
        </Modal>
      }
    </div>
  );
};

export default IntakeWizard;

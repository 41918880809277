// import moment_tz from "moment_tz";
const moment_tz = require("moment-timezone");
const moment = require("moment");
const _ = require("lodash");
const { getCurrentMerckWeek, getWeekDayNumberFromWeekDayName } = require("../services/helper");

let date_config = {
  "BlueSky Rehost": {
    default_mig_date: {
      weekday: "Saturday",
      time: "7:00",
      prop_type: "datetime",
    },
    migweek: {
      prop_type: "migweek",
    },
    migdate: {
      prop_type: "migdate",
    },
    approvals_instruction_date: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "date",
    },
    approvals_instruction_time: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "time",
    },
    application_shutdown_notice: {
      weekday: "Wednesday",
      relative_week_number: 0,
      prop_type: "date",
    },
    downtime_begins_date: {
      weekday: "N",
      time: "N",
      prop_type: "date",
    },
    downtime_begins_time: {
      weekday: "N",
      time: "N",
      prop_type: "time",
    },
    downtime_complete_date: {
      relative_time: "+08:00",
      prop_type: "date",
    },
    downtime_complete_time: {
      relative_time: "+08:00",
      prop_type: "time",
    },
    hypercare_begins_date: {
      relative_time: 10,
      prop_type: "date",
    },
    hypercare_begins_time: {
      relative_time: 10,
      prop_type: "time",
    },
    migration_complete: {
      weekday: "Friday",
      relative_week_number: 2,
      prop_type: "date",
    },
  },
  "MCS": {
    default_mig_date: {
      weekday: "Saturday",
      time: "7:00",
      prop_type: "datetime",
    },
    migweek: {
      prop_type: "migweek",
    },
    migdate: {
      prop_type: "migdate",
    },
    approvals_instruction_date: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "date",
    },
    approvals_instruction_time: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "time",
    },
    application_shutdown_notice: {
      weekday: "Wednesday",
      relative_week_number: 0,
      prop_type: "date",
    },
    downtime_begins_date: {
      weekday: "N",
      time: "N",
      prop_type: "date",
    },
    downtime_begins_time: {
      weekday: "N",
      time: "N",
      prop_type: "time",
    },
    downtime_complete_date: {
      relative_time: "+08:00",
      prop_type: "date",
    },
    downtime_complete_time: {
      relative_time: "+08:00",
      prop_type: "time",
    },
    hypercare_begins_date: {
      relative_time: 10,
      prop_type: "date",
    },
    hypercare_begins_time: {
      relative_time: 10,
      prop_type: "time",
    },
    migration_complete: {
      weekday: "Friday",
      relative_week_number: 2,
      prop_type: "date",
    },
  },
  "RIVERMEADOW": {
    default_mig_date: {
      weekday: "Saturday",
      time: "7:00",
      prop_type: "datetime",
    },
    migweek: {
      prop_type: "migweek",
    },
    migdate: {
      prop_type: "migdate",
    },
    approvals_instruction_date: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "date",
    },
    approvals_instruction_time: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "time",
    },
    application_shutdown_notice: {
      weekday: "Wednesday",
      relative_week_number: 0,
      prop_type: "date",
    },
    downtime_begins_date: {
      weekday: "N",
      time: "N",
      prop_type: "date",
    },
    downtime_begins_time: {
      weekday: "N",
      time: "N",
      prop_type: "time",
    },
    downtime_complete_date: {
      relative_time: "+08:00",
      prop_type: "date",
    },
    downtime_complete_time: {
      relative_time: "+08:00",
      prop_type: "time",
    },
    hypercare_begins_date: {
      relative_time: 10,
      prop_type: "date",
    },
    hypercare_begins_time: {
      relative_time: 10,
      prop_type: "time",
    },
    migration_complete: {
      weekday: "Friday",
      relative_week_number: 2,
      prop_type: "date",
    },
  },
  "RVM": {
    default_mig_date: {
      weekday: "Saturday",
      time: "7:00",
      prop_type: "datetime",
    },
    migweek: {
      prop_type: "migweek",
    },
    migdate: {
      prop_type: "migdate",
    },
    approvals_instruction_date: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "date",
    },
    approvals_instruction_time: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "time",
    },
    application_shutdown_notice: {
      weekday: "Wednesday",
      relative_week_number: 0,
      prop_type: "date",
    },
    downtime_begins_date: {
      weekday: "N",
      time: "N",
      prop_type: "date",
    },
    downtime_begins_time: {
      weekday: "N",
      time: "N",
      prop_type: "time",
    },
    downtime_complete_date: {
      relative_time: "+08:00",
      prop_type: "date",
    },
    downtime_complete_time: {
      relative_time: "+08:00",
      prop_type: "time",
    },
    hypercare_begins_date: {
      relative_time: 10,
      prop_type: "date",
    },
    hypercare_begins_time: {
      relative_time: 10,
      prop_type: "time",
    },
    migration_complete: {
      weekday: "Friday",
      relative_week_number: 2,
      prop_type: "date",
    },
  },

  GreenLake: {
    default_mig_date: {
      prop_type: "datetime",
      weekday: "Thursday",
      time: "8:00",
      prod: {
        weekday: "Friday",
        time: "7:00",
      },
    },
    migweek: {
      prop_type: "migweek",
    },
    migdate: {
      prop_type: "migdate",
    },
    approvals_instruction_date: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "date",
    },
    approvals_instruction_time: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "time",
    },
    application_shutdown_notice: {
      weekday: "Tuesday",
      prop_type: "date",
    },
    downtime_begins_date: {
      weekday: "N",
      time: "N",
      prop_type: "date",
    },
    downtime_begins_time: {
      weekday: "N",
      time: "N",
      prop_type: "time",
    },
    downtime_complete_date: {
      relative_time: 4,
      prop_type: "date",
    },
    downtime_complete_time: {
      relative_time: 4,
      prop_type: "time",
    },
    hypercare_begins_date: {
      relative_time: 6,
      prop_type: "date",
    },
    hypercare_begins_time: {
      relative_time: 6,
      prop_type: "time",
    },
    migration_complete: {
      weekday: "Friday",
      relative_week_number: 2,
      prop_type: "date",
    },
    approval_due_date: {
      prop_type: "date",
      relative_days: 12,
    },
  },
  "GreenLake SRM": {
    default_mig_date: {
      prop_type: "datetime",
      weekday: "Thursday",
      time: "8:00",
      prod: {
        weekday: "Friday",
        time: "7:00",
      },
    },
    migweek: {
      prop_type: "migweek",
    },
    migdate: {
      prop_type: "migdate",
    },
    approvals_instruction_date: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "date",
    },
    approvals_instruction_time: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "time",
    },
    application_shutdown_notice: {
      weekday: "Tuesday",
      prop_type: "date",
    },
    downtime_begins_date: {
      weekday: "N",
      time: "N",
      prop_type: "date",
    },
    downtime_begins_time: {
      weekday: "N",
      time: "N",
      prop_type: "time",
    },
    downtime_complete_date: {
      relative_time: 4,
      prop_type: "date",
    },
    downtime_complete_time: {
      relative_time: 4,
      prop_type: "time",
    },
    hypercare_begins_date: {
      relative_time: 6,
      prop_type: "date",
    },
    hypercare_begins_time: {
      relative_time: 6,
      prop_type: "time",
    },
    migration_complete: {
      weekday: "Friday",
      relative_week_number: 2,
      prop_type: "date",
    },
    approval_due_date: {
      prop_type: "date",
      relative_days: 12,
    },
  },
  "GreenLake Live": {
    default_mig_date: {
      prop_type: "datetime",
      weekday: "Thursday",
      time: "8:00",
      prod: {
        weekday: "Friday",
        time: "7:00",
      },
    },
    migweek: {
      prop_type: "migweek",
    },
    migdate: {
      prop_type: "migdate",
    },
    approvals_instruction_date: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "date",
    },
    approvals_instruction_time: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "time",
    },
    application_shutdown_notice: null,
    downtime_begins_date: {
      weekday: "N",
      time: "N",
      prop_type: "date",
    },
    downtime_begins_time: {
      weekday: "N",
      time: "N",
      prop_type: "time",
    },
    downtime_complete_date: null,
    downtime_complete_time: null,
    hypercare_begins_date: {
      relative_time: 10,
      prop_type: "date",
    },
    hypercare_begins_time: {
      relative_time: 6,
      prop_type: "time",
    },
    migration_complete: {
      weekday: "Friday",
      relative_week_number: 2,
      prop_type: "date",
    },
    approval_due_date: {
      prop_type: "date",
      relative_days: 12,
    },
  },
  APEX: {
    default_mig_date: {
      prop_type: "datetime",
      weekday: "Thursday",
      time: "8:00",
      prod: {
        weekday: "Friday",
        time: "7:00",
      },
    },
    migweek: {
      prop_type: "migweek",
    },
    migdate: {
      prop_type: "migdate",
    },
    approvals_instruction_date: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "date",
    },
    approvals_instruction_time: {
      weekday: "Friday",
      relative_week_number: -5,
      prop_type: "date",
    },
    application_shutdown_notice: {
      weekday: "Tuesday",
      prop_type: "date",
    },
    downtime_begins_date: {
      weekday: "N",
      time: "N",
      prop_type: "date",
    },
    downtime_begins_time: {
      weekday: "N",
      time: "N",
      prop_type: "time",
    },
    downtime_complete_date: {
      relative_time: 4,
      prop_type: "date",
    },
    downtime_complete_time: {
      relative_time: 4,
      prop_type: "time",
    },
    hypercare_begins_date: {
      relative_time: 6,
      prop_type: "date",
    },
    hypercare_begins_time: {
      relative_time: 6,
      prop_type: "time",
    },
    migration_complete: {
      weekday: "Friday",
      relative_week_number: 2,
      prop_type: "date",
    },
    approval_due_date: {
      prop_type: "date",
      relative_days: 12,
    },
  },
  Robo: {
    default_mig_date: {
      prop_type: "datetime",
      weekday: "Thursday",
      time: "8:00",
      prod: {
        weekday: "Friday",
        time: "7:00",
      },
    },
    migweek: {
      prop_type: "migweek",
    },
    migdate: {
      prop_type: "migdate",
    },
    approvals_instruction_date: {
      prop_type: "date",
      weekday: "Friday",
      relative_week_number: -4,
    },
    approvals_instruction_time: {
      prop_type: "time",
      weekday: "Friday",
      relative_week_number: -4,
    },
    application_shutdown_notice: {
      weekday: "Tuesday",
      relative_week_number: -0,
      prop_type: "date",
    },
    downtime_begins_date: {
      prop_type: "date",
      weekday: "N",
      time: "N",
    },
    downtime_begins_time: {
      prop_type: "time",
      weekday: "N",
      time: "N",
    },
    downtime_complete_date: {
      prop_type: "date",
      relative_time: 4,
    },
    downtime_complete_time: {
      prop_type: "time",
      relative_time: 4,
    },
    hypercare_begins_date: {
      prop_type: "date",
      relative_time: 6,
    },
    hypercare_begins_time: {
      prop_type: "time",
      relative_time: 6,
    },
    migration_complete: {
      prop_type: "date",
      weekday: "Friday",
      relative_week_number: 1,
    },
    approval_due_date: {
      prop_type: "date",
      relative_days: 5,
    },
  },
  RoboP2V: {
    default_mig_date: {
      prop_type: "datetime",
      weekday: "Thursday",
      time: "8:00",
      prod: {
        weekday: "Friday",
        time: "7:00",
      },
    },
    migweek: {
      prop_type: "migweek",
    },
    migdate: {
      prop_type: "migdate",
    },
    approvals_instruction_date: {
      prop_type: "date",
      weekday: "Friday",
      relative_week_number: -4,
    },
    approvals_instruction_time: {
      prop_type: "time",
      weekday: "Friday",
      relative_week_number: -4,
    },
    application_shutdown_notice: {
      prop_type: "date",
      weekday: "Tuesday",
      relative_week_number: 0,
    },
    downtime_begins_date: {
      prop_type: "date",
      weekday: "N",
      time: "N",
    },
    downtime_begins_time: {
      prop_type: "time",
      weekday: "N",
      time: "N",
    },
    downtime_complete_date: {
      prop_type: "date",
      relative_time: 4,
    },
    downtime_complete_time: {
      prop_type: "time",
      relative_time: 4,
    },
    hypercare_begins_date: {
      prop_type: "date",
      relative_time: 6,
    },
    hypercare_begins_time: {
      prop_type: "time",
      relative_time: 6,
    },
    migration_complete: {
      prop_type: "date",
      weekday: "Friday",
      relative_week_number: 1,
    },
    approval_due_date: {
      prop_type: "date",
      relative_days: 12,
    },
  },
  GreelakeLIVE: {
    default_mig_date: {
      prop_type: "datetime",
      weekday: "Thursday",
      time: "8:00",
    },
    migweek: {
      prop_type: "migweek",
    },
    migdate: {
      prop_type: "migdate",
    },
    approvals_instruction_date: {
      prop_type: "date",
      weekday: "Friday",
      relative_week_number: -5,
    },
    approvals_instruction_time: {
      prop_type: "time",
      weekday: "Friday",
      relative_week_number: -5,
    },
    application_shutdown_notice: null,
    downtime_begins_date: {
      prop_type: "date",
      weekday: "N",
      time: "N",
    },
    downtime_begins_time: {
      prop_type: "time",
      weekday: "N",
      time: "N",
    },
    downtime_complete: null,
    hypercare_begins_date: {
      prop_type: "date",
      relative_time: 10,
    },
    hypercare_begins_time: {
      prop_type: "time",
      relative_time: 10,
    },
    migration_complete: {
      prop_type: "date",
      weekday: "Friday",
      relative_week_number: 2,
    },
    approval_due_date: {
      prop_type: "date",
      relative_days: 12,
    },
  },
  DEFAULT: {
    default_mig_date: {
      prop_type: "datetime",
      weekday: "Thursday",
      time: "8:00",
    },
    migweek: {
      prop_type: "migweek",
    },
    migdate: {
      prop_type: "migdate",
    },
    approvals_instruction_date: {
      prop_type: "date",
      weekday: "Friday",
      relative_week_number: -5,
    },
    approvals_instruction_time: {
      prop_type: "time",
      weekday: "Friday",
      relative_week_number: -5,
    },
    application_shutdown_notice: null,
    downtime_begins_date: {
      prop_type: "date",
      weekday: "N",
      time: "N",
    },
    downtime_begins_time: {
      prop_type: "time",
      weekday: "N",
      time: "N",
    },
    downtime_complete: null,
    hypercare_begins_date: {
      prop_type: "date",
      relative_time: 10,
    },
    hypercare_begins_time: {
      prop_type: "time",
      relative_time: 10,
    },
    migration_complete: {
      prop_type: "date",
      weekday: "Friday",
      relative_week_number: 2,
    },
    approval_due_date: {
      prop_type: "date",
      relative_days: 12,
    },
  },
};

//from low to high - Just for reference
let compute_order_precendece = [
  "relative_week_number", // Positive or negative integer
  "relative_time", // positive or negative HH:mm format
  "weekday", // Monday, Tuesday, Wednesday ... or N
  "time", //HH:mm format or N
  "week_number", //not introduced yet in date_config
];

const get_date_config_property = (migtype, date_config_prop_name, N_date, N_time, env, use_default = true, date_format = null) => {
  if (typeof date_config_prop_name === "string") {
    return _get_date_config_property(migtype, date_config_prop_name, N_date, N_time, env, use_default, date_format);
  }
  if (Array.isArray(date_config_prop_name)) {
    let result = {};
    date_config_prop_name.forEach((item) => {
      if (_.isObject(item)) {
        if (_.has(item, "date_config_prop_name") && _.has(item, "output_prop_name")) {
          result[item["output_prop_name"]] = _get_date_config_property(
            migtype,
            item["date_config_prop_name"],
            N_date,
            N_time,
            env,
            use_default,
            _.has(item, "date_format") ? item["date_format"] : null
          );
          return;
        }
      } else {
        result[item] = _get_date_config_property(migtype, item, N_date, N_time, env, use_default);
      }
    });

    return result;
  }
  if (_.isObject(date_config_prop_name)) {
    let result = {};
    Object.keys(date_config_prop_name).forEach((key) => {
      result[key] = _get_date_config_property(migtype, key, N_date, N_time, env, use_default);

      return result;
    });
  }

  return null;
};
const _get_date_config_property = (
  migtype,
  date_config_prop_name,
  N_date,
  N_time,
  env = null,
  use_default = true,
  date_format = null
) => {
  let config_details = find_date_config_from_map(migtype, date_config_prop_name, env, use_default);
  if (config_details === null) {
    return { computed_value: null, string_value: "--", human_readable_propname: "" };
  }
  return compute_date(migtype, { ...config_details, date_config_prop_name }, N_date, N_time, env, date_format)
};

export const compute_date = (
  migtype,
  config_details,
  N_date,
  N_time,
  env = null,
  date_format = null) => {
  let _moment_N_datetime = moment_tz();
  moment_tz.tz.setDefault("America/New_York");

  if (!N_date && !N_time) {
    let default_mig_datetime = find_date_config_from_map(migtype, "default_mig_date", env, true);
    let current_merck_weeknum = getCurrentMerckWeek().split("W")[1];

    _moment_N_datetime
      .startOf("year")
      .add(+current_merck_weeknum, "weeks")
      .subtract(1, "week")
      .weekday(getWeekDayNumberFromWeekDayName(default_mig_datetime["weekday"]))
      .add(parseInt(default_mig_datetime["time"].split(":")[0]), "hours")
      .add(parseInt(default_mig_datetime["time"].split(":")[1]), "minutes");
  } else if (moment.isMoment(N_date)) {
    _moment_N_datetime = N_date
  }
  else {
    // generate a moment_tz date-time object
    _moment_N_datetime = moment_tz(N_date + " " + N_time);
  }

  //after setting the correct config details, check the type and generate the required data
  let computed_value = _moment_N_datetime.clone();


  if (typeof config_details?.["relative_week_number"] === "number") {
    computed_value.add(config_details?.["relative_week_number"], "weeks");
  }
  if (typeof config_details?.["relative_time"] === "string") {
    let relative_time = config_details?.["relative_time"];
    let operation = relative_time.substring(0, 1) == "-" ? "subtract" : "add";
    let hours = parseInt(relative_time.substring(1, 3));
    let mins = parseInt(relative_time.substring(4, 6));

    computed_value[operation](hours, "hours");
    computed_value[operation](mins, "minutes");
  }
  if (typeof config_details?.["relative_time"] === "number") {
    let relative_time = config_details?.["relative_time"];
    let operation = relative_time < 0 ? "subtract" : "add";
    computed_value[operation](relative_time, "hours");
    computed_value[operation](0, "minutes");
  }
  if (typeof config_details?.['relative_days'] === 'number') {
    computed_value.add(config_details?.["relative_days"], 'days')
  }

  if (typeof config_details?.["weekday"] == "string") {
    let weekday = config_details?.["weekday"];
    let weekday_num = 0;
    if (weekday === "N") {
      weekday_num = _moment_N_datetime.weekday();
    } else {
      weekday_num = getWeekDayNumberFromWeekDayName(weekday);
    }
    computed_value.weekday(weekday_num);
  }
  if (typeof config_details?.["time"] === "string") {
    let time = config_details?.["time"];
    let hours = 0,
      mins = 0;

    if (time === "N") {
      hours = _moment_N_datetime.hours();
      mins = _moment_N_datetime.minutes();
    }
    hours = parseInt(time.substring(0, 2));
    mins = parseInt(time.substring(3, 5));
    computed_value.hours(hours).minutes(mins);
  }

  let string_value = "";
  if (date_format !== null) {
    string_value = computed_value.format(date_format);
  } else {
    if (config_details["prop_type"] === "date") {
      string_value = computed_value.format("YYYY-MM-DD");
    }
    if (config_details["prop_type"] === "time") {
      string_value = computed_value.format("h:mm A");
    }
    if (config_details["prop_type"] === "datetime") {
      string_value = computed_value.format("YYYY-MM-DD HH:mm");
    }
    if (config_details["prop_type"] === "migweek") {
      string_value = computed_value.format("YY") + "W" + computed_value.isoWeek().toString().padStart(2, '0');
    }
    if (config_details["prop_type"] === "migdate") {
      string_value = computed_value.format("MMMM DD, YYYY");
    }
  }
  let human_readable_propname = ''
  if (config_details['date_config_prop_name']) {
    human_readable_propname = generate_human_readable_propname(config_details['date_config_prop_name']);
  }
  return { computed_value, string_value, human_readable_propname };
}

const generate_human_readable_propname = (prop_name) => {
  let arr = prop_name.split("_");
  arr = arr.map((str) => _.upperFirst(str));
  return arr.join(" ");
};

export const find_date_config_from_map = (
  migtype,
  date_config_prop_name,
  env = null,
  use_default = true,
  return_null_on_failure = true
) => {
  let config_details = date_config?.[migtype]?.[date_config_prop_name];
  if (env !== null) {
    config_details = config_details?.[env] ? config_details?.[env] : null;
  }

  if (!config_details) {
    if (use_default) {
      if (date_config?.[migtype]?.[date_config_prop_name]) {
        config_details = date_config?.[migtype]?.[date_config_prop_name];
      } else if (date_config["DEFAULT"]?.[date_config_prop_name]) {
        config_details = date_config["DEFAULT"]?.[date_config_prop_name];
      } else {
        throw new Error("DEFAULT config does not exist for the given property");
      }
    } else {
      if (return_null_on_failure) {
        return null;
      }
      throw new Error("Request date config does not exist. Contact Priyank.");
    }
  }

  if (!config_details?.["prop_type"]) {
    throw new Error(`prop_type property not found in ${migtype} > ${date_config_prop_name}`);
  }

  return config_details;
};

export const set_date_config = (migtype, date_config_obj) => {
  if (!_.isObject(date_config_obj)) {
    throw new Error("Please enter a valid Javascript object");
  }
  if (date_config?.[migtype]) {
    date_config[migtype] = { ...date_config[migtype], ...date_config_obj };
  } else {
    date_config[migtype] = date_config_obj;
  }

  return true
};
export default get_date_config_property;

// date_config['BS']['default']['app_shutdown_notice']
// date_config['BS']['app_shutdown_notice']['default']

import { Button, Col, Input, List, Radio, Row, Typography } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { STATUS_TYPES } from "./types";
import moment from "moment";

const V2SingleQuestion = ({
  questionnaire_key = null,
  question_key = null,
  admin_comments = "",
  responder_comments = "",
  q_status = STATUS_TYPES.INITIAL,
  updated_by,
  updated_at,
  questionnaire_admin = false,
  on_submit,
  selectedServers,
  questionDetails
}) => {
  const { TextArea } = Input;
  const { Text } = Typography;

  const [form_data, set_form_data] = useState({
    admin_comments: admin_comments,
    responder_comments: responder_comments,
    q_status: q_status,
  });
  const [buttonLabel, setButtonLabel] = useState("SAVE");
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (!questionnaire_admin && form_data.q_status === STATUS_TYPES.SUBMITTED) {
      setButtonLabel("Edit");
      setIsEditable(false);
    } else {
      setButtonLabel("Save");
      setIsEditable(true);
    }
  }, [form_data.q_status, questionnaire_admin]);

  const onSubmit = () => {
    if (buttonLabel === "Edit") {
      setIsEditable(true);
      setButtonLabel("Save");
      return;
    }
    let submit_data = {};

    if (form_data.admin_comments !== admin_comments) {
      //Submit Admin Comment
      submit_data["admin_comments"] = form_data.admin_comments;
      submit_data["q_status"] = form_data["q_status"];
    }
    if (form_data.responder_comments !== responder_comments) {
      submit_data["responder_comments"] = form_data.responder_comments;
      submit_data["q_status"] = STATUS_TYPES.SUBMITTED;
    }
    if (
      questionnaire_admin &&
      form_data["q_status"] != STATUS_TYPES.NEED_RESPONSE &&
      (q_status == STATUS_TYPES.INITIAL || q_status == STATUS_TYPES.SUBMITTED)
    ) {
      form_data["q_status"] = STATUS_TYPES.COMPLETE;
    }

    if (form_data.q_status !== q_status) {
      submit_data["q_status"] = form_data["q_status"];
    }

    if (!_.isEmpty(submit_data) && _.isFunction(on_submit)) {
      const success = on_submit({ [question_key]: submit_data });
      if (success) {
        if (!questionnaire_admin) {
          setButtonLabel("Edit");
          setIsEditable(false);
        }

        set_form_data((prev) => ({
          ...prev,
          admin_comments: submit_data.admin_comments || prev.admin_comments,
          responder_comments:
            submit_data.responder_comments || prev.responder_comments,
          q_status: submit_data.q_status || prev.q_status,
        }));
      }
    }
  };

  const serversList = selectedServers?.map((server) => server.Servername);

  console.log("for check", selectedServers);
  console.log("serversList", serversList);


  return (
    <>
      {questionDetails['selected_servers']?.length >= 0? (
        <Row>
          { questionDetails['selected_servers']?.length === 0 ?(
            <Text>No servers selected</Text>
          ):(<List
            style={{ width: "25%" }}
            size="small"
            header={<div style={{ fontWeight: "bold" }}>Servers</div>}
            bordered
            dataSource={serversList}
            renderItem={(item) => <List.Item>{item}</List.Item>}
            pagination={{
              pageSize: 5, 
            }}
          />)
          }
          
        </Row>
      ) : (
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <p>Admin's Comment</p>
            <TextArea
              rows={1}
              value={form_data.admin_comments}
              onChange={(e) =>
                set_form_data({ ...form_data, admin_comments: e.target.value })
              }
              style={{ color: "red" }}
              disabled={
                !questionnaire_admin ||
                form_data.q_status === STATUS_TYPES.COMPLETE
              }
              placeholder={
                !questionnaire_admin && _.isEmpty(admin_comments)
                  ? "No comments are provided by admin"
                  : ""
              }
            />
          </Col>
          <Col span={24}>
            {questionnaire_admin ? (
              <p>Response: </p>
            ) : (
              <p>Type your response below</p>
            )}
            <Row>
              <Col span={24}>
                <TextArea
                  style={{ color: "black" }}
                  rows={3}
                  value={form_data.responder_comments}
                  onChange={(e) =>
                    set_form_data({
                      ...form_data,
                      responder_comments: e.target.value,
                    })
                  }
                  disabled={
                    !isEditable ||
                    questionnaire_admin ||
                    form_data.q_status === STATUS_TYPES.COMPLETE
                  }
                  placeholder={
                    questionnaire_admin &&
                    _.isEmpty(form_data.responder_comments)
                      ? "No comments are provided by the responder"
                      : " "
                  }
                />
              </Col>
            </Row>
          </Col>
          {questionnaire_admin ? (
            <Col span={24}>
              <Radio.Group
                onChange={(e) =>
                  set_form_data({
                    ...form_data,
                    q_status: e.target.value,
                  })
                }
                defaultValue={
                  form_data.q_status !== STATUS_TYPES.NEED_RESPONSE
                    ? STATUS_TYPES.COMPLETE
                    : STATUS_TYPES.NEED_RESPONSE
                } // Default to 'COMPLETE'
              >
                <Radio value={STATUS_TYPES.COMPLETE}>Mark as Reviewed</Radio>
                <Radio value={STATUS_TYPES.NEED_RESPONSE}>Need Response</Radio>
              </Radio.Group>
            </Col>
          ) : (
            <></>
          )}
          {updated_at ? (
            <Col span={24}>
              <Text disabled>
                Last Updated at {moment(updated_at).format("MM/DD/YY, h:mm A")}{" "}
                by {updated_by}
              </Text>
            </Col>
          ) : (
            <></>
          )}

          <Col span={24}>
            <Button onClick={onSubmit}>{buttonLabel}</Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default V2SingleQuestion;
